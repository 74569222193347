import stage from './stage';
import { Environment } from '.';

const developmentEnvironment: Environment = {
  ...stage,
  doDeployUpdate: false,
  featureFlags: {
    enableProjectTypes: true,
    supportCompanyOnJobPost: true,
    showCompanyOnJobPost: true,
    disableRateOnSignup: true,
    enableExperienceLevel: true,
    enablePublicCandidatePage: true,
    enableInviteToApply: true,
    enableRecruiterAssignment: true,
    showApplicantNotes: true,
  },
};

export default developmentEnvironment;
