/* eslint-disable object-shorthand */
/* eslint-disable func-names */
import { Api, BaseAPI, setAuthorizationToken } from '../../Api';

export const AuthAPI = {
  checkEmail: async (data: { email: string }) => {
    const response: CheckEmailStatuses = await Api.post(
      '/auth/check-email',
      data
    );
    return response;
  },

  getUser: async (userId: string | undefined | null, isPublic: boolean) => {
    if (!userId) return Promise.resolve<any>(null);
    const user: UserEntity = await (isPublic
      ? Api.get(`/user/${userId}/public`)
      : Api.get(`/user/${userId}`));
    return user;
  },

  getMembership: async (membershipId: string | undefined | null) => {
    if (!membershipId) return Promise.resolve<any>(null);
    const membership: MembershipEntity = await Api.get(
      `/membership/${membershipId}`
    );
    return membership;
  },

  getUserAndMembership: async function(jwt: UserJWT, isPublic = false) {
    const { id, membershipId } = jwt.user;
    const [user, membership] = await Promise.all([
      this.getUser(id, isPublic),
      this.getMembership(membershipId),
    ]);
    return { user, membership };
  },

  authenticate: async function(data: LoginUser | MagicLogin) {
    const jwt = await Api.post('/auth/login', data);
    setAuthorizationToken(jwt.accessToken);
    const { user, membership } = await this.getUserAndMembership(jwt);
    return { jwt, user, membership };
  },

  register: async function(data: RegisterUser) {
    const jwt = await Api.post('/user', data);
    setAuthorizationToken(jwt.accessToken);
    const { user, membership } = await this.getUserAndMembership(jwt);
    return { jwt, user, membership };
  },

  registerPartialProfile: async function(data: RegisterUser, id?: string) {
    const jwt = await Api.patch(`/user/${id}/register-partial-profile`, data);
    setAuthorizationToken(jwt.accessToken);
    const { user, membership } = await this.getUserAndMembership(jwt);
    return { jwt, user, membership };
  },

  verify: async function(accessToken: string) {
    setAuthorizationToken(accessToken);
    const jwt = await Api.get('/auth/verify');
    return jwt;
  },

  inviteStatus: async (data: { code: string }) => {
    const response = await Api.get('/user/invite', data);
    return response;
  },

  // registerWithInvite: async (data: RegisterInviteUser) => {
  //   const response = await Api.post('/user', data);
  //   return response;
  // },

  deactivate: async (id: string) => {
    const response = await Api.patch(`/user/${id}/deactivate`);
    return response;
  },

  reactivate: async (id: string) => {
    const response = await Api.patch(`/user/${id}/reactivate`);
    return response;
  },

  refreshTokens: async function(token: string) {
    // override the authorization header since the token
    // is the refresh token, instead of the access token.
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const jwt = await Api.post('/auth/refresh', {}, config);
    setAuthorizationToken(jwt.accessToken);
    return jwt;
  },

  refresh: async function(token: string) {
    const jwt = await this.refreshTokens(token);
    const { user, membership } = await this.getUserAndMembership(jwt);
    return { jwt, user, membership };
  },

  forgotPassword: async function(data: ForgotPassword) {
    const response = await Api.post('/auth/reset-password', data);
    return response;
  },

  resetPassword: async function(data: ResetPassword) {
    const response = await Api.post('/auth/update-password', data);
    return response;
  },

  become: async function(id: string) {
    const jwt = await Api.post(`/auth/become/${id}`);
    setAuthorizationToken(jwt.accessToken);
    const { user, membership } = await this.getUserAndMembership(jwt);
    return { jwt, user, membership };
  },

  setMagicOp: async function(data: MagicOp) {
    const response = await Api.patch(`/magic-op`, data);
    return response;
  },

  sendMagicLink: async function(data: MagicLink) {
    const response = await Api.post(`/auth/magic`, data);
    return response;
  },

  sendMagicLinkAuth: async function() {
    const response = await Api.post(`/auth/magic/mine`);
    return response;
  },

  registerPushToken: async function(data: {
    token: string;
    type: string;
    status: string;
    deviceUuid?: string;
    deviceInfo?: object;
  }) {
    const response = await Api.post('/user/push-notification-token', data);
    return response;
  },

  associateRole: async function(userId: string, roleId: string) {
    const response = await Api.post(`/user/${userId}/role/${roleId}`);
    return response;
  },

  associateSkill: async function(userId: string, skillId: string) {
    const response = await Api.post(`/user/${userId}/skill/${skillId}`);
    return response;
  },

  setAuthorizationToken: (token: string | undefined) =>
    setAuthorizationToken(token),

  associateEthnicities: async function(userId: string, ids: string[]) {
    const response = await Api.put(`/user/${userId}/ethnicities`, {
      ethnicityIds: ids,
    });
    return response;
  },

  associateAuthProvider: async function(id: string, data: any) {
    const response = await Api.post(`/user/oauth-provider`, {
      ...data,
      oauthProviderId: id,
    });
    return response;
  },

  updateAuthProvider: async function(data: Record<string, any>) {
    const response = await Api.patch(`/user/oauth-provider`, {
      ...data,
    });
    return response;
  },

  getAllowList: async function() {
    const response = await Api.get('/allowlist');
    return response;
  },
};

export default { ...BaseAPI('user'), ...AuthAPI };
