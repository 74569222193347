import React, { useState } from 'react';
import { IonAlert } from '@ionic/react';
import { useHistory } from 'react-router';
import { useQueryClient } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import { ListActionsItemButton } from '../../listActions';
import useChatChannelInstance from '../hooks/useChatChannelInstance';
import favorApi from '../../generosity/Api';
import { addSuccess, addError } from '../../../services/Messaging';
import { IChannel } from '../../../@types/chat.d';
import ChannelQueries from '../../generosity/queries';
import { LoadingBalls } from '../../core/Loading';

const RemoveIcon = () => <i className="i-alert-remove text-grey-600 text-lg" />;

type MemberLeaveChannelProps = {
  channel: IChannel;
};

const MemberLeaveChannel = ({ channel }: MemberLeaveChannelProps) => {
  const history = useHistory();
  const { channelInstance } = useChatChannelInstance(channel.sid);
  const [showLeavePrompt, setShowLeavePrompt] = useState(false);

  const joinedChannels = ChannelQueries.useGetJoinedChannels({
    select: data => data.filter(d => d.conversationId === channel.sid),
  });

  const queryClient = useQueryClient();

  if (joinedChannels.status === 'error') {
    addError(`An error occurred while attempting to leave the channel`);
    Sentry.captureException(joinedChannels.error);
    return null;
  }

  if (joinedChannels.status === 'loading') {
    return <LoadingBalls isActive />;
  }

  const handleLeaveChannel = async () => {
    try {
      const alreadyJoinedChannel = joinedChannels.data[0];
      await favorApi.interact(alreadyJoinedChannel.id, 'passed');
      await channelInstance.leave();

      addSuccess('Successfully left channel');

      queryClient.invalidateQueries(['channels']);
      history.push('/channels');
    } catch (error) {
      Sentry.captureException(error);
      addError('An error occurred while leaving the channel');
    }
  };

  return (
    <>
      <ListActionsItemButton
        end={<RemoveIcon />}
        onClick={() => setShowLeavePrompt(true)}
      >
        Leave Conversation
      </ListActionsItemButton>
      <IonAlert
        isOpen={showLeavePrompt}
        onDidDismiss={() => {
          setShowLeavePrompt(false);
        }}
        header="Leave conversation?"
        message="Are you sure you want to leave this conversation?"
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {},
          },
          {
            text: 'Remove',
            handler: handleLeaveChannel,
          },
        ]}
      />
    </>
  );
};

export default MemberLeaveChannel;
