import React, { useMemo } from 'react';
import { parse, stringify } from 'querystringify';
import { useLocation } from 'react-router';
import MobileListWorkApplications from './MobileListWorkApplications';
import { isMobile } from '../../utilities/Device';
import ApplicationPreview from './ApplicationPreview';
import useNavigate from '../../hooks/useNavigate';
import WorkCandidatesBoard from './WorkCandidatesBoard';
import Modal from '../core/modal/Modal';

type Props = {
  applications: KeyedApplications;
  hire: (applicationId: string, sendEmail: boolean) => void;
  work: WorkEntity;
  status: ApplicationStatus | null;
  selected: ApplicationEntity | null;
  patchApplications: Function;
};

const ListWorkApplications = ({
  applications,
  hire,
  work,
  status,
  selected,
  patchApplications,
}: Props) => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  const handleDismiss = () => {
    const { pathname: path } = window.location;
    const params = parse(search) as any;

    if (params.applicationId) delete params.applicationId;
    // When closing the modal, the path will be the same.
    // When clicking a link within the modal, the paths will be different.
    if (pathname === path)
      navigate(`${pathname}?${stringify(params)}`, 'none', 'replace');
  };

  // Memoized applicationPreview
  const ApplicationPreviewMemo = useMemo(
    () => (
      <>
        {selected && (
          <ApplicationPreview
            work={work}
            application={selected}
            patchApplications={patchApplications}
            hire={hire}
          />
        )}
      </>
    ),
    [selected, work, patchApplications, hire]
  );

  return (
    <>
      {isMobile ? (
        <MobileListWorkApplications
          work={work}
          applications={applications}
          status={status || 'interested'}
        />
      ) : (
        <div className="flex flex-1 grow overflow-hidden">
          <WorkCandidatesBoard
            applications={applications}
            patchApplications={patchApplications}
          />
          <div className="hidden md:block min-w-64 max-w-64 lg:min-w-84 lg:max-w-84 ml-4">
            {ApplicationPreviewMemo}
          </div>
        </div>
      )}

      {isMobile && selected && (
        <Modal
          fullHeight
          title={`${selected.user.firstName} ${selected.user.lastName}`}
          isOpen={!!selected}
          paddingX="4"
          onClose={handleDismiss}
        >
          {ApplicationPreviewMemo}
        </Modal>
      )}
    </>
  );
};

export default React.memo(ListWorkApplications);
