import { Capacitor } from '@capacitor/core';
import {
  IonBackButton,
  IonIcon,
  IonLabel,
  IonSegment,
  IonSegmentButton,
} from '@ionic/react';
import { useQueryClient } from '@tanstack/react-query';
import classnames from 'classnames';
import { addCircle } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { page } from '../../analytics';
import useCurrentMembership from '../../hooks/useCurrentMembership';
import useNavigate from '../../hooks/useNavigate';
import { addError, addSuccess } from '../../services/Messaging';
import { isMobile } from '../../utilities/Device';
import uuid from '../../utilities/uuid';
import { LoadingBalls } from '../core/Loading';
import CardEmpty from './CardEmpty';
import ChannelCard from './ChannelCard';
import CreateChannelForm from './CreateChannelForm';
import JoinChannel from './JoinChannel';
import Pane from './Pane';
import ChannelQueries from './queries';
import UsersCards from './UsersCards';

interface PaneProps {
  onCancel: Function;
  isActive: boolean;
  cardId: TCardId;
}

const defaultPaneState = {
  onCancel: () => {},
  isActive: false,
  cardId: '',
};

const View = () => {
  const navigate = useNavigate();
  const [helpPane, setHelpPane] = useState<PaneProps>(defaultPaneState);
  const [createPane, setCreatePane] = useState(false);
  const [, setMultiBtnActive] = useState(false);
  const [currentTab, setCurrentTab] = useState<'all' | 'mine'>('all');
  const { canCreateChannels } = useCurrentMembership().plan as PlanEntity;
  const getAllChannels = ChannelQueries.useGetAllChanels();
  const interactWithChannel = ChannelQueries.useInteractWithChannel();

  // A bit of a hacky way to ensure the Mine tab is refeshed
  // when a new favor is created by the user
  const [refreshKey, setRefreshKey] = useState(uuid());

  const handleCreateComplete = () => {
    addSuccess('Card has been created.');
    setCreatePane(false);
    setMultiBtnActive(false);
    setRefreshKey(uuid());
  };

  const queryClient = useQueryClient();

  const handleChannelView = (id: TCardId) => {
    interactWithChannel.mutate(
      { cardId: id.toString() },
      {
        onSuccess: d => {
          queryClient.invalidateQueries(['channels']);
          navigate(`/chat/conversation/${d.conversationId}`);
        },
      }
    );
  };

  const handleTabChange = (e: any) => setCurrentTab(e.detail.value);

  useEffect(() => {
    page('Generosity', { view: currentTab });
  }, [currentTab]);

  if (getAllChannels.status === 'loading') {
    return <LoadingBalls isActive />;
  }

  if (getAllChannels.status === 'error') {
    addError('There was an error while fetching available channels');
    return null;
  }

  return (
    <>
      <div className="lg:relative">
        <div className="flex generosity__header">
          {isMobile ? (
            <IonBackButton
              className="text-grey-900 text-base z-10"
              defaultHref="/feed"
            />
          ) : (
            <></>
          )}
          <div className="generosity__segment">
            <IonSegment
              value={currentTab}
              mode="ios"
              onIonChange={handleTabChange}
            >
              <IonSegmentButton value="all">
                <IonLabel>New</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="mine">
                <IonLabel>Joined</IonLabel>
              </IonSegmentButton>
            </IonSegment>
          </div>
          {canCreateChannels && (
            <button
              type="button"
              className="self-center ml-4 mt-1"
              onClick={() => setCreatePane(true)}
            >
              <span id="add-channel-label" hidden>
                Add a channel
              </span>
              <IonIcon size="large" icon={addCircle} />
            </button>
          )}
        </div>

        <div className="w-fit items-center">
          {getAllChannels.data.length > 0 ? (
            <div
              className={`${
                currentTab !== 'mine'
                  ? 'grid sm:grid-col-1 md:grid-cols-2 2xl:grid-cols-3 gap-4 justify-items-center place-content-center'
                  : 'hidden'
              } `}
            >
              {getAllChannels.data.map(favor => (
                <ChannelCard
                  currentTab={currentTab}
                  details={favor}
                  onViewChannel={() => handleChannelView(favor.id)}
                />
              ))}
            </div>
          ) : (
            <div className="card-empty">
              <CardEmpty isLoading={getAllChannels.isFetching} />
            </div>
          )}
        </div>

        <div
          className={`w-fit max-w-full px-5 ${
            currentTab === 'mine' ? 'flex' : 'hidden'
          }`}
        >
          <UsersCards
            currentTab={currentTab}
            handleTabChange={setCurrentTab}
            onView={handleChannelView}
            key={refreshKey}
          />
        </div>
        <Pane
          name="offer help"
          isActive={helpPane.isActive}
          transition="slideFromLeft"
          className={classnames({
            'pt-1 px-0': Capacitor.isNativePlatform(),
            'pt-16': !Capacitor.isNativePlatform(),
          })}
        >
          <JoinChannel
            onClosePane={() => {
              setHelpPane(state => ({
                ...state,
                isActive: false,
              }));
            }}
            cardId={helpPane.cardId}
          />
        </Pane>

        <Pane
          name="create favor"
          isActive={createPane}
          transition="slideFromBottom"
          className={classnames({
            'pt-10 px-2': Capacitor.isNativePlatform(),
            'pt-28 md:pt-40': !Capacitor.isNativePlatform(),
          })}
        >
          <CreateChannelForm
            onCancel={() => setCreatePane(false)}
            onComplete={handleCreateComplete}
          />
        </Pane>
      </div>
    </>
  );
};

export default View;
