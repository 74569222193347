import { Environment } from '.';

const productionEnvironment: Environment = {
  appEnvironment: 'production',
  appUrl: 'https://app.communo.com',
  apiUrl: 'https://api.communo.com',
  headerColor: 'black',
  algoliaAppId: 'TA57NGSS9M',
  algoliaPrefix: 'prod',
  cloudinaryApiKey: '291367397962851',
  cloudinaryCloudName: 'communo',
  logRocketAppId: 'ccigen/communo-prod',
  googleMapsApiKey: 'AIzaSyDU86Q3vkQqe8tF4lVk7PXaiCxst-Pd7Ug',
  recurlyPublicKey: 'ewr1-JhyfWy1HohXZYwgCHeXXR6',
  firebaseConfig: {
    apiKey: 'AIzaSyDU86Q3vkQqe8tF4lVk7PXaiCxst-Pd7Ug',
    authDomain: 'communo-prod.firebaseapp.com',
    databaseUrl: 'https://communo-prod.firebaseio.com',
    projectId: 'communo-prod',
    storageBucket: 'communo-prod.appspot.com',
    messagingSenderId: '255208632364',
    appId: '1:255208632364:web:f2f263179ab28c379ca189',
    measurementId: 'G-2PLKN4PE31',
  },
  segmentKey: 'Ut7ahDcYUZYmWwDucMtBAoJ3E3RSwfRc',
  sentryDsn:
    'https://c4323a25a295445a8f2b9cdf7af2e1d9@o348555.ingest.sentry.io/2222907',
  youtubeChannelId: 'UClLwdCQX_-Fjlat9dqrYEng',
  feedVideoPlaylistId: 'PLBe3PLrvzMA-sMa_xUB0MjovPo82kqeKi',
  chatMessagesGroupingInterval: 1000 * 60 * 15, // 15 minutes,
  chatMessagesPerPage: 20,
  chatChannelsPerPage: 10,
  chatRoles: {
    RL1257651dae5e4edd8a01c69952331d92: {
      type: 'channelAdmin',
      label: 'Admin',
    },
    RLb8d6e55d70204e9a90edb12c31765023: {
      type: 'channelMember',
      label: 'Member',
    },
    RL5034ca9ed4084554b3b05c9dd473bf9b: {
      type: 'serviceAdmin',
      label: 'Service Admin',
    },
    RL8621ad18b809430d9a905b7dda246571: {
      type: 'serviceUser',
      label: 'User',
    },
  },
  opengraphKey: '3e401a6e-adc8-4712-80dc-c1b7bda3f631',
  beatsMembershipId: '4cd5b01ff9654cebbcaf72f0ba1c59ea',
  doDeployUpdate: true,
  zendeskKey: 'f88b0d88-1969-4b4e-928f-af41e8d948cd',
  featureFlags: {
    enableProjectTypes: false,
    supportCompanyOnJobPost: false,
    showCompanyOnJobPost: false,
    disableRateOnSignup: true,
    enableExperienceLevel: false,
    enablePublicCandidatePage: false,
    enableInviteToApply: false,
    enableRecruiterAssignment: false,
    showApplicantNotes: false,
  },
};

export default productionEnvironment;
