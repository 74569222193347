import React, { useMemo, useState } from 'react';
import { find } from 'linkifyjs';
import useAsyncEffect from 'use-async-effect';
import ReactPlayer from 'react-player/lazy';
import { JSONValue } from '@twilio/conversations';
import parse from 'html-react-parser';
import { IMessage } from '../../../@types/chat.d';
import decodeMessage from '../utilities/decodeMessage';
import fetchLinkPreview from '../services/fetchLinkPreview';
import { canPlayVideo } from '../../video/VideoPlayer';
import { isMobile } from '../../../utilities/MatchMedia';

type MessageBodyProps = {
  message: IMessage;
  onUpdateAttributes: (payload: JSONValue) => void;
};

const MessageBody = ({ message, onUpdateAttributes }: MessageBodyProps) => {
  const { body, attributes } = message;
  const linkData = attributes?.linkData;

  const [previewFailed, setPreviewFailed] = useState(linkData === null);
  const decodedMessage = useMemo(() => decodeMessage(body), [body]);

  const isSharedLink = body.includes('shared-channel-link');

  const stringToDisplay = isSharedLink ? parse(body) : decodedMessage;

  const links = useMemo(() => find(decodedMessage), [decodedMessage]);
  const hasLinks = links.length > 0 && links[0].type === 'url';
  const hasVideo =
    links.length > 0 && canPlayVideo(links[0].value) ? links[0].value : null;
  const videoPreview = hasVideo?.includes('cloudinary')
    ? `${hasVideo.substr(0, hasVideo.lastIndexOf('.'))}.gif`.replace(
        '/upload/',
        '/upload/du_5/e_loop/'
      )
    : false;
  const hasLinkData = useMemo(() => linkData, [linkData]);

  useAsyncEffect(async () => {
    if (hasLinks && !hasLinkData && !previewFailed) {
      const link = links[0].href;
      let data;

      if (link.match(/\.(jpeg|jpg|gif|png)$/) !== null) {
        data = {
          title: '',
          siteName: 'Image Link',
          description: '',
          image: link,
          url: link,
        };
      } else if (link.match(/\.(webm|ogg|mp4)$/) !== null) {
        setPreviewFailed(true);
        if (videoPreview) {
          data = {
            time: Date.now(),
          };
        } else {
          data = null;
        }
      } else {
        try {
          data = await fetchLinkPreview(link);
        } catch (error) {
          // Using a timeout so the preview placeholder doesn't flash
          setTimeout(() => setPreviewFailed(true), 2000);
          data = null;
        }
      }

      onUpdateAttributes({ linkData: data });
    }
  }, []);

  return (
    <>
      {/* eslint-disable-next-line no-nested-ternary */}
      {hasLinks ? (
        hasVideo ? (
          <div className="relative">
            <div className="relative overflow-hidden mt-4">
              <ReactPlayer
                url={hasVideo}
                config={{
                  file: {
                    attributes: {
                      poster:
                        (hasLinkData && !attributes?.linkData?.time) ||
                        (attributes?.linkData?.time &&
                          attributes?.linkData?.time < Date.now() - 5000)
                          ? videoPreview
                          : null,
                    },
                  },
                }}
                controls
                width={isMobile ? '100%' : 'auto'}
                height={isMobile ? 'auto' : '450px'}
              />
            </div>
          </div>
        ) : (
          <>{stringToDisplay}</>
        )
      ) : (
        decodedMessage
      )}
    </>
  );
};

export default React.memo(MessageBody);
