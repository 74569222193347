import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import Conversation from '../components/chat/views/Conversation';
import useChatChannel from '../components/chat/hooks/useChatChannel';
import { LoadingBalls } from '../components/core/Loading';
import useChatClient from '../components/chat/hooks/useChatClient';
import View from '../components/core/View';
import { isDesktop } from '../utilities/MatchMedia';
import ChannelQueries from '../components/generosity/queries';
import useNavigate from '../hooks/useNavigate';
import { addSuccess } from '../services/Messaging';

const ChatConversation = ({ match }: any) => {
  const navigate = useNavigate();
  const { sid } = match.params;
  const { loaded } = useChatClient();
  const { channel } = useChatChannel(sid);

  const queryClient = useQueryClient();

  const interactWithChannel = ChannelQueries.useInteractWithChannel();

  ChannelQueries.useGetAllChanels({
    refetchOnWindowFocus: false,
    onSuccess: channels => {
      // TODO: add an endpoint that can fetch channels based on sid
      const channelToJoin = channels?.find(ch => ch.conversationId === sid);

      if (channelToJoin !== undefined) {
        interactWithChannel.mutate(
          { cardId: channelToJoin.id.toString() },
          {
            onSuccess: data => {
              queryClient.invalidateQueries(['channels']);
              navigate(`/chat/conversation/${data.conversationId}`);
              addSuccess(`Joined "${channelToJoin.title}"`);
            },
          }
        );
      }
    },
  });

  if (isDesktop) {
    if (!channel || !loaded) return <LoadingBalls isActive fullscreen />;
    return <Conversation channel={channel} key={sid} />;
  }

  return (
    <View
      className="page-bg--white"
      authGuard
      darkStatusBar
      pageTitle="Chat Conversation"
    >
      {!channel || !loaded ? (
        <LoadingBalls isActive fullscreen />
      ) : (
        <Conversation channel={channel} />
      )}
    </View>
  );
};

export default ChatConversation;
