import { Preferences } from '@capacitor/preferences';
import { App } from '@capacitor/app';
import * as Sentry from '@sentry/react';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { parse } from 'querystringify';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { identify, reset } from '../../analytics';
import { setAuthorizationToken } from '../../Api';
import { APP_ENVIRONMENT, ENABLE_PUBLIC_CANDIDATE_PAGE } from '../../config';
import { addResponseError } from '../../services/Messaging';
import getCookie from '../../utilities/cookie';
import { LoadingBalls } from '../core/Loading';
import { INVITE_ID_COMMUNO_COOKIE } from '../landing/CollectionInviteLanding';
import MembershipApi from '../membership/Api';
import initMembershipCompletenessStore from '../membership/services/initMembershipCompletenessStore';
import Api from './Api';
import initUserCompletenessStore from './services/initUserCompletenessStore';

const UserContext = React.createContext({} as UserContextInterface);

class UserProviderClass extends React.PureComponent<
  { history: any },
  UserContextState
> {
  private componentIsMounted: boolean = false;

  private tokenTimer: NodeJS.Timeout | undefined;

  private deltaSeconds: number;

  constructor(props: any) {
    super(props);

    this.deltaSeconds = 0;

    this.state = {
      jwt: null,
      user: null,
      membership: null,
      authDetails: {
        email: '',
        firstName: '',
        lastName: '',
        avatarURL: '',
        oauthProviderId: undefined,
      },
      initializing: true,
      redirectUrl: null,
      showOnboarding: false,
      publicMagicKey: null,
    };
  }

  async componentDidMount() {
    this.isMounted = true;
    this.addListeners();

    // Don't load the current user if we are in the 'public' area.
    if (!(await this.authenticateMagicJwt())) {
      this.loadUserFromStorage();
    }
  }

  componentWillUnmount() {
    this.isMounted = false;
    this.removeListeners();
  }

  get isMounted() {
    return this.componentIsMounted;
  }

  set isMounted(status: boolean) {
    this.componentIsMounted = status;
  }

  get timeUntilTokenRefresh() {
    const { jwt } = this.state;
    if (!jwt) return 0;

    const decodedAccessToken: DecodedAccessToken = jwtDecode(jwt.accessToken);

    const { exp } = decodedAccessToken;

    const now = moment().unix();

    // Flip the delta to handle when the time is set ahead or behind the server's time
    // Flipping allows us to be able to add the delta to the local time to get the server time
    const flippedDelta = this.deltaSeconds * -1;
    const currentTime = now + flippedDelta;

    if (exp < currentTime) {
      this.setState({
        jwt: {
          ...jwt,
          searchToken: null,
        },
      });
    }

    const bufferSeconds = 60;
    const secondsUntilRefresh = exp - currentTime - bufferSeconds;

    // the next timeout will be a minute before the expiry,
    // or right away if it's 0
    return Math.max(secondsUntilRefresh * 1000, 0);
  }

  get canUpgrade(): boolean {
    const { jwt } = this.state;
    return (
      // must be admin
      jwt?.user.membershipAccess === 'admin'
    );
  }

  get isOnboardingRequired(): boolean {
    const { user, membership } = this.state;
    return this.checkOnboarding(user, membership);
  }

  addListeners = () => {
    // focus fires when the page becomes active (eg clicking into browser)
    window.addEventListener('focus', this.onWindowFocus);

    // appStateChange fires when the tab becomes active, app is opened/brought to focus
    App.addListener('appStateChange', ({ isActive }: { isActive: boolean }) => {
      if (isActive) this.onWindowFocus();
    });
  };

  removeListeners = () => {
    window.removeEventListener('focus', this.onWindowFocus);
  };

  onWindowFocus = () => {
    // when the user resumes focus of the window/tab
    // reset the refresh token timer
    this.refreshTokenTimer();
  };

  loadUserFromStorage = () => {
    Preferences.get({ key: 'currentUser' }).then(
      ({ value }: { value: string | null }) => {
        const data = value ? JSON.parse(value) : null;
        // stored data structure has changed, so handle legacy storage (data.refreshToken)
        const refreshToken =
          data?.jwt?.refreshToken || data?.refreshToken || null;

        if (refreshToken) {
          const { pathname } = window.location;
          // always do an initial refresh if there is a current user loaded for a few reasons:
          // 1. If there access token is still valid, but their refresh token has been invalidated, this will speed up the process of invalidating their access
          // 2. If their account no longer exists, has been disabled, etc, this can be handled right away
          // 3. API calls **should** be fast enough that this initial load could be near instant, AND if it is not, have a fallback to load the rest of the app while it's waiting.
          // For EXAMPLE setTimeout() for x seconds to automatically set initializing to false and set the jwt and user state from local storage in initially.
          // Also avoid a race condition where direct access to /logout will also fire off a refresh, re-setting the current user
          if (pathname === '/logout') this.logout();
          else this.refresh(refreshToken, true);
        } else {
          // Otherwise ready to load the reset
          this.setState({ initializing: false });
        }
      }
    );
  };

  onIdentify = (data: AuthenticatedUser) => {
    const { user: existingUser } = this.state;
    const { user } = data;
    const { email, firstName, lastName, primaryMembershipUser } = user;
    const { name: planName } =
      primaryMembershipUser?.membership.plan || ({ name: null } as any);
    const payload = {
      Email: email,
      'First Name': firstName,
      'Last Name': lastName,
      'Plan Name': planName,
    };

    if (data.jwt?.user?.originalUserId) {
      // Now logged-in as another user, don't identify
      return;
    }

    // if a different user is already logged in, reset analytics
    if (existingUser && existingUser.id === user.id) {
      reset();
    }

    // identify user
    identify(user.id, payload);
  };

  setUser = (data: AuthenticatedUser | null) => {
    if (!this.isMounted) return;

    const { jwt, user, membership } = data || ({} as any);

    const decodedAccessToken: DecodedAccessToken = jwtDecode(jwt.accessToken);
    this.deltaSeconds = moment().unix() - decodedAccessToken.now;

    this.setState({
      jwt,
      user,
      membership,
      initializing: false,
    });

    // refresh token timer
    this.refreshTokenTimer();

    // Persist the data (Storage automatically selects best available storage)
    Preferences.set({
      key: 'currentUser',
      value: JSON.stringify(data),
    });

    // Identify user for LogRocket
    // if (APP_ENVIRONMENT === 'production' && data?.user?.id) {
    //   LogRocket.identify(data.user.id, {
    //     name: `${data.user.firstName} ${data.user.lastName}`,
    //     email: data.user.email,
    //   });
    // }

    // Identify user for Sentry
    if (APP_ENVIRONMENT === 'production' && data?.user?.id) {
      Sentry.configureScope(scope => {
        scope.setUser({
          email: data.user.email,
          id: data.user.id,
          username: `${data.user.firstName} ${data.user.lastName}`,
        });
      });
    }
  };

  checkOnboarding = (
    user: UserEntity | null,
    membership: MembershipEntity | null
  ) => {
    if (!user || !membership) return false;
    if (!user.firstName || !user.lastName) return true;
    if (user.isPasswordEmpty && user.oauthProviders?.length === 0) return true;
    if (!user.location?.latitude) return true;
    // if (user.roles.length < 1) return true;
    // if (!user.isEthnicitySet) return true;

    // If user is admin, also prompt them to fill out agency requirements
    if (
      membership &&
      user.primaryMembershipUser?.membershipAccess === 'admin' &&
      user.primaryMembershipUser?.membership.plan.type === 'agency'
    ) {
      if (!membership.name) return true;
      if (!membership.location?.latitude) return true;
      // if (membership.roles.length < 1) return true;
      if (!membership.size) return true;
    }

    if (
      membership &&
      user.primaryMembershipUser?.membershipAccess === 'admin' &&
      user.primaryMembershipUser?.membership.plan.type === 'brand'
    ) {
      if (!membership.name) return true;
      if (!membership.location?.latitude) return true;
      if (!membership.size) return true;
    }

    // If user is AAF, prompt to fill out AAF Chapter
    if (
      membership &&
      membership.plan.name === 'AAF' &&
      !user.jsonAttributes?.aafChapter
    ) {
      return true;
    }

    if (membership && membership.plan.subType === 'hbcu') {
      if (user?.education?.length === 0) return true;
    }

    // If user accept a collection invite, tell them they've been added
    const inviteIdCookie = getCookie(INVITE_ID_COMMUNO_COOKIE);
    if (inviteIdCookie) {
      return true;
    }

    return false;
  };

  setOnboarding = (bool: boolean) => {
    this.setState({ showOnboarding: bool });
  };

  handleOnboarding = (
    user: UserEntity | null,
    membership: MembershipEntity | null
  ) => this.setOnboarding(this.checkOnboarding(user, membership));

  refreshTokenTimer = () => {
    // Avoid multiple timers, clear first if previously set
    if (this.tokenTimer) clearTimeout(this.tokenTimer);
    this.tokenTimer = setTimeout(() => {
      this.handleRefresh();
    }, this.timeUntilTokenRefresh);
  };

  handleRefresh = async () => {
    const { jwt } = this.state;

    if (!jwt) return Promise.resolve<any>(null); // Promise.reject(new Error('User not logged in'));

    return this.refresh(jwt.refreshToken);
  };

  refresh = async (refreshToken: string, checkOnboarding = false) => {
    // disable subsequent refreshes (setCurrentUser will re-initialize the refresh token update)
    if (this.tokenTimer) clearTimeout(this.tokenTimer);

    return Api.refresh(refreshToken)
      .then((response: AuthenticatedUser) => {
        this.onIdentify(response);
        this.setUser(response);

        initUserCompletenessStore(response.user);

        if (response.membership) {
          initMembershipCompletenessStore(response.membership);
        }

        if (checkOnboarding) {
          const { pathname } = window.location;
          // avoid showing onboarding overlay on certain pages
          if (!pathname.startsWith('/plans'))
            this.handleOnboarding(response.user, response.membership);
        }
        return response;
      })
      .catch(error => {
        // clear user data?
        this.setState({ initializing: false });
        addResponseError(error);
        return error;
      })
      .finally(() => {});
  };

  refreshMembership = async () => {
    const { membership } = this.state;

    if (!membership?.id) return Promise.resolve<MembershipEntity | null>(null);

    return MembershipApi.retrieve(membership.id).then(
      (response: MembershipEntity) => {
        this.setState({ membership: response });

        initMembershipCompletenessStore(response);

        return response;
      }
    );
  };

  // login user
  authenticate = (data: MagicLogin | LoginUser) => {
    return Api.authenticate(data).then((response: AuthenticatedUser) => {
      this.setAuthResponse(response);
      this.handleOnboarding(response.user, response.membership);
      return response;
    });
  };

  // register user
  register = (data: RegisterUser) => {
    return Api.register(data).then((response: AuthenticatedUser) => {
      this.setAuthResponse(response);
      return response;
    });
  };

  // register partial profile
  registerPartialProfile = (data: RegisterUser, id?: string) => {
    return Api.registerPartialProfile(data, id).then(
      (response: AuthenticatedUser) => {
        this.setAuthResponse(response);
        return response;
      }
    );
  };

  // register user
  registerInvite = (data: RegisterInviteUser) => {
    return Api.register(data).then((response: AuthenticatedUser) => {
      this.setAuthResponse(response);
      this.handleOnboarding(response.user, response.membership);
      return response;
    });
  };

  authenticateJwt = async (jwt: any) => {
    setAuthorizationToken(jwt.accessToken);

    const { user, membership } = await Api.getUserAndMembership(jwt);

    this.setAuthResponse({ jwt, user, membership });
    this.handleOnboarding(user, membership);
  };

  authenticateMagicJwt = async (): Promise<boolean> => {
    if (!ENABLE_PUBLIC_CANDIDATE_PAGE) {
      return false;
    }

    const { search } = window.location;
    const searchResults = parse(search) as any;
    const jwt = searchResults?.magicKey;

    if (!jwt) {
      return false;
    }

    return Api.verify(jwt)
      .then(() => {
        setAuthorizationToken(jwt);
        this.setState({ publicMagicKey: jwt, initializing: false });
        return true;
      })
      .catch(() => {
        this.setState({ publicMagicKey: null });
        return false;
      });
  };

  setAuthResponse = (response: AuthenticatedUser) => {
    this.onIdentify(response);
    this.setUser(response);
  };

  become = (id: string) => {
    return Api.become(id).then((response: AuthenticatedUser) => {
      this.setUser(response);
      return response;
    });
  };

  sendMagicLink = (data: MagicLink) => {
    return Api.sendMagicLink(data).then(response => {
      return response;
    });
  };

  magicLogin = (data: MagicLogin) => {
    return this.authenticate(data);
  };

  update = (data: Partial<UserEntity>) => {
    const { user } = this.state;

    // optimistically set the data?
    // this.setState({ user: { ...user, ...data } });
    return Api.update(user?.id || '', data).then((response: UserEntity) => {
      this.setState({ user: { ...(user || ({} as UserEntity)), ...data } });
      return response;
    });
  };

  updateMembership = (data: Partial<MembershipEntity>) => {
    const { membership } = this.state;

    return MembershipApi.update(membership?.id || '', data).then(
      (response: MembershipEntity) => {
        this.setState({
          membership: { ...(membership || ({} as any)), ...data },
        });
        return response;
      }
    );
  };

  // logout user
  logout = (callback?: () => void) => {
    if (this.tokenTimer) clearTimeout(this.tokenTimer);
    reset();
    this.setState({ jwt: null, user: null, showOnboarding: false }, callback);
    Preferences.clear();
    window.location.href = '/';
  };

  handleChangeAuthDetails = (
    details: Partial<AuthDetails>,
    callback?: () => void
  ) => {
    this.setState(
      prev => ({
        ...prev,
        authDetails: {
          ...prev.authDetails,
          ...details,
        },
      }),
      callback
    );
  };

  setRedirectUrl = (url: string | null) => {
    this.setState({ redirectUrl: url });
  };

  render() {
    const { children } = this.props;
    const {
      authDetails,
      jwt,
      user,
      membership,
      initializing,
      redirectUrl,
      showOnboarding,
      publicMagicKey,
    } = this.state;

    const firstName = user?.firstName || '';
    const lastName = user?.lastName || '';
    const name = firstName || lastName ? `${firstName} ${lastName}` : '';

    return (
      <UserContext.Provider
        value={{
          authDetails,
          isAuthenticated: !!jwt?.accessToken,
          isPublic: !!publicMagicKey,
          publicMagicKey,
          isAdmin: jwt?.user.membershipAccess === 'admin' || false,
          isRoot: jwt?.user.isRoot || false,
          hasMembership: !!jwt?.user.membershipId,
          canUpgrade: this.canUpgrade,
          membershipId: jwt?.user.membershipId || undefined,
          membershipAccess: jwt?.user.membershipAccess || null,
          planType: jwt?.user.planType || undefined,
          searchToken: jwt?.searchToken || '',
          messagingToken: jwt?.messagingToken || '',
          id: user?.id ?? '',
          originalUserId: jwt?.user.originalUserId,
          firstName,
          lastName,
          name,
          avatarURL: user?.avatarURL || null,
          user,
          membership,
          redirectUrl,
          showOnboarding,
          isOnboardingRequired: this.isOnboardingRequired,
          update: this.update,
          updateMembership: this.updateMembership,
          setShowOnboarding: this.setOnboarding,
          setRedirectUrl: this.setRedirectUrl,
          handleChangeAuthDetails: this.handleChangeAuthDetails,
          authenticate: this.authenticate,
          register: this.register,
          registerPartialProfile: this.registerPartialProfile,
          registerInvite: this.registerInvite,
          authenticateJwt: this.authenticateJwt,
          setAuthResponse: this.setAuthResponse,
          become: this.become,
          sendMagicLink: this.sendMagicLink,
          logout: this.logout,
          refresh: () => {
            // eslint-disable-next-line no-console
            console.warn('user context refresh will be deprecated soon');
            return this.handleRefresh();
          },
          refreshMembership: this.refreshMembership,
        }}
      >
        {/* <Loading isActive={initializing} /> */}
        {initializing && <LoadingBalls isActive fullscreen />}
        {!initializing && children}
      </UserContext.Provider>
    );
  }
}

export const UserProvider = withRouter(({ history, children }) => (
  <UserProviderClass history={history}>{children}</UserProviderClass>
));

export const UserConsumer = React.memo(UserContext.Consumer);

export default UserContext;
