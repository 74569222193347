import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ChannelInteraction } from '../../@types/chat.d';
import favorApi from './Api';

type FavorInteractionMutationResult = UseMutationResult<
  TFavorInteractionVariables,
  AxiosError,
  TFavorInteractionVariables
>;

type ChannelQueries = {
  useAddChannelModerator: () => FavorInteractionMutationResult;
  useRemoveChannelModerator: () => FavorInteractionMutationResult;
  useToggleChannelState: () => UseMutationResult<
    Omit<TFavorInteractionVariables, 'userId'>,
    AxiosError,
    Omit<TFavorInteractionVariables, 'userId'>
  >;
  useBlockMember: () => FavorInteractionMutationResult;
  useGetBlockedMembers: <Entity>(
    favorId: string,
    options?: UseQueryOptions<Entity, AxiosError>
  ) => UseQueryResult<Entity, AxiosError>;
  useUnblockMember: () => FavorInteractionMutationResult;
  useRemoveChannelParticipant: () => FavorInteractionMutationResult;
  useGetAllChanels: (
    options?: UseQueryOptions<ChannelInteraction[], AxiosError>
  ) => UseQueryResult<ChannelInteraction[], AxiosError>;
  useGetJoinedChannels: (
    options?: UseQueryOptions<ChannelInteraction[], AxiosError>
  ) => UseQueryResult<ChannelInteraction[], AxiosError<unknown, any>>;
  useInteractWithChannel: () => UseMutationResult<
    ChannelInteraction,
    AxiosError,
    { cardId: string }
  >;
};

const ChannelQueries: ChannelQueries = {
  useAddChannelModerator: () => {
    return useMutation(details => favorApi.addAdminToChannel(details));
  },

  useRemoveChannelModerator: () => {
    return useMutation(details =>
      favorApi.revertChannelAdminToParticipant(details)
    );
  },
  useToggleChannelState: () => {
    return useMutation(details => favorApi.toggleChannelState(details));
  },
  useBlockMember: () => {
    return useMutation(details => favorApi.blockMember(details));
  },
  useGetBlockedMembers: <Entity>(
    favorId: string,
    options?: UseQueryOptions<Entity, AxiosError>
  ) => {
    return useQuery<Entity, AxiosError>(
      ['members', { filter: 'blocked' }],
      () => favorApi.getBlockedMembers(favorId),
      options
    );
  },
  useUnblockMember: () => {
    const queryClient = useQueryClient();

    return useMutation(details => favorApi.unblockMember(details), {
      onSuccess: () => {
        queryClient.invalidateQueries(['members', { filter: 'blocked' }]);
      },
    });
  },
  useRemoveChannelParticipant: () => {
    return useMutation(details => favorApi.removeChannelParticipant(details));
  },
  useGetAllChanels: (
    options?: UseQueryOptions<ChannelInteraction[], AxiosError>
  ) => {
    return useQuery<ChannelInteraction[], AxiosError>(
      ['channels', { filter: 'all' }],
      favorApi.list,
      options
    );
  },
  useGetJoinedChannels: (
    options?: UseQueryOptions<ChannelInteraction[], AxiosError>
  ) => {
    return useQuery<ChannelInteraction[], AxiosError>(
      ['channels', { filter: 'joined' }],
      favorApi.listMine,
      options
    );
  },
  useInteractWithChannel: (): UseMutationResult<
    ChannelInteraction,
    AxiosError,
    { cardId: string }
  > => {
    return useMutation(details =>
      favorApi.interact(details.cardId, 'offered', {
        visibility: 'public',
      })
    );
  },
};

export default ChannelQueries;
