/* eslint-disable no-console */
import React, { useContext, useState } from 'react';
import {
  IonItemSliding,
  IonItem,
  IonLabel,
  IonItemOptions,
  IonItemOption,
  IonAlert,
} from '@ionic/react';
import { useHistory } from 'react-router';
import UserContext from '../../user/Context';
import { relativeDate } from '../../../utilities/dateTime';
import ChannelPlaceholder from '../../core/placeholder/ChannelPlaceholder';
import useChatMessages from '../hooks/useChatMessages';
import useChatUser from '../hooks/useChatUser';
import decodeMessage from '../utilities/decodeMessage';
import splitName from '../../../utilities/splitName';
import ChannelName from './ChannelName';
import { IChannel, IMessage } from '../../../@types/chat.d';
import ReadReceipts from './ReadReceipts';
import ChannelProfile from './ChannelProfile';
import useChatChannelInstance from '../hooks/useChatChannelInstance';
import useChatChannelMember from '../hooks/useChatChannelMember';
import useCurrentUser from '../../../hooks/useCurrentUser';
import MemberInstances from '../instances/MemberInstances';
import { Link } from '../../core/router';

type ChannelLastMessageProps = {
  message: IMessage;
};

const ChannelLastMessage = React.memo(
  ({ message }: ChannelLastMessageProps) => {
    const { author, body } = message;
    const { user, loaded } = useChatUser(author);
    const { id } = useContext(UserContext);

    if (!user || !loaded) return null;

    const authorName =
      author === id ? 'You' : splitName(user.friendlyName)?.first;

    const isSharedLink = body.includes('shared-channel-link');

    const stringToDisplay = isSharedLink
      ? body.substr(0, body.indexOf('<br/>'))
      : decodeMessage(body);

    return (
      <>
        <span className="font-semibold">{authorName}:</span>{' '}
        {` ${stringToDisplay}`}
      </>
    );
  }
);

const ChannelLastMessagePresenter = React.memo(
  ({ message }: { message: IMessage }) => {
    if (message.author === 'system') return <>{decodeMessage(message.body)}</>;

    return <ChannelLastMessage message={message} />;
  }
);

const MuteOption = React.memo(({ channelSid }: { channelSid: string }) => {
  const { id } = useCurrentUser();
  const { member } = useChatChannelMember(channelSid, id || '');

  const isNotifiable = member?.attributes?.isNotifiable ?? true;

  const handleMute = async () => {
    const MemberInstance = MemberInstances.select(member.sid);
    await MemberInstance.updateAttributes({ isNotifiable: !isNotifiable });
  };

  return (
    <IonItemOption onClick={handleMute} color="communo-option-secondary">
      <div slot="top" className="block w-full text-center">
        {isNotifiable ? (
          <i className="i-notification text-xl" />
        ) : (
          <i className="i-mute-notification text-xl" />
        )}
      </div>
      <div className="text-center">{isNotifiable ? 'Mute' : 'Unmute'}</div>
    </IonItemOption>
  );
});

const LeaveOption = React.memo(({ channelSid }: { channelSid: string }) => {
  const history = useHistory();
  const { channelInstance } = useChatChannelInstance(channelSid);
  const [showLeavePrompt, setShowLeavePrompt] = useState(false);

  const handleLeaveChannel = async () => {
    await channelInstance.leave();
    history.replace('/chat');
  };

  return (
    <>
      <IonItemOption
        onClick={() => setShowLeavePrompt(true)}
        color="communo-option-primary"
      >
        <div slot="top" className="block w-full text-center">
          <i className="i-leave text-xl" />
        </div>
        <div className="text-center">Leave</div>
      </IonItemOption>
      <IonAlert
        isOpen={showLeavePrompt}
        onDidDismiss={() => {
          setShowLeavePrompt(false);
        }}
        header="Leave conversation?"
        message="Are you sure you want to leave this conversation?"
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {},
          },
          {
            text: 'Remove',
            handler: handleLeaveChannel,
          },
        ]}
      />
    </>
  );
});

const Channel = ({
  channel,
  isActive,
}: {
  channel: IChannel;
  isActive: boolean;
}) => {
  const { sid, friendlyName, attributes } = channel;
  const { type } = attributes;
  const isDirect = type === 'direct';

  const { messages, unreadCount, loaded } = useChatMessages(sid);
  const { id } = useCurrentUser();
  const { member } = useChatChannelMember(sid, id || '');

  const isNotifiable = member?.attributes?.isNotifiable ?? true;

  const lastMessage = messages.length ? messages[messages.length - 1] : null;
  const lastMsgDate = lastMessage?.dateCreated ?? null;
  const lastMsgIndex = lastMessage?.index || 0;

  const itemClasses = [
    'chat-item',
    unreadCount > 0 && !isActive ? 'chat-item--unread' : '',
    !isNotifiable ? 'chat-item--muted' : '',
  ];

  if (!loaded) {
    return <ChannelPlaceholder />;
  }

  return (
    <IonItemSliding className={isActive ? 'is-active' : ''}>
      <Link to={`/chat/conversation/${sid}`}>
        <IonItem>
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
          <IonLabel tabIndex={0}>
            <div className="flex items-center">
              <div>
                <ChannelProfile
                  channelSid={sid}
                  friendlyName={friendlyName}
                  attributes={attributes}
                  avatarSize="lg"
                  format="avatar"
                />
              </div>
              <div className="flex-1 min-w-0">
                <div className={itemClasses.join(' ')}>
                  <div className="chat-item__body">
                    <div className="chat-item__title">
                      <ChannelName
                        channelSid={channel.sid}
                        friendlyName={friendlyName}
                        attributes={attributes}
                      />
                    </div>
                    <div className="chat-item__snippet">
                      {lastMessage ? (
                        <ChannelLastMessagePresenter message={lastMessage} />
                      ) : (
                        'You currently have no messages.'
                      )}
                    </div>
                  </div>
                  <div className="chat-item__meta">
                    <div className="chat-item__date">
                      {lastMsgDate && relativeDate(lastMsgDate)}
                    </div>
                    <div className="chat-item__status">
                      {/* eslint-disable-next-line no-nested-ternary */}
                      {!isActive ? (
                        unreadCount > 0 ? (
                          <div className="badge">{unreadCount}</div>
                        ) : (
                          <ReadReceipts
                            channelSid={channel.sid}
                            msgIndex={lastMsgIndex}
                          />
                        )
                      ) : null}
                    </div>
                    {!isNotifiable ? (
                      <div className="chat-item__status">
                        <i className="i-mute-notification text-md" />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </IonLabel>
        </IonItem>
      </Link>
      <IonItemOptions side="end">
        <MuteOption channelSid={sid} />
        {!isDirect ? <LeaveOption channelSid={sid} /> : null}
      </IonItemOptions>
    </IonItemSliding>
  );
};

export default Channel;
