import React, { useMemo } from 'react';
// eslint-disable-next-line no-unused-vars
import { SearchResponse } from '@algolia/client-search';

import Paginate from '../../core/Paginate';
import Filter from '../../filter/Filter';
import WorkCard from '../../card/WorkCard';
import useNavigate from '../../../hooks/useNavigate';
import useFeatureGate from '../../../hooks/useFeatureGate';
import useCurrentPlan from '../../../hooks/useCurrentPlan';
import Button from '../../core/Button';

type Props = {
  filterContext: any;
  searchResults: SearchResponse;
  work: any[];
  onLoadMore: Function;
};

function groupWorkByExternal(
  work: any[]
): {
  internal: WorkEntity[];
  external: WorkEntity[];
} {
  return work.reduce(
    (carry: any, workItem: WorkEntity) => {
      // Group all work together if user cannot apply to Communo posts
      const group = workItem.isExternal ? 'external' : 'internal';
      carry[group].push(workItem);
      return carry;
    },
    {
      internal: [],
      external: [],
    }
  );
}

type WorkResultsProps = {
  workItems: WorkEntity[];
  cardStyle: 'limited' | 'full' | 'external';
};
const WorkResults = React.memo(({ workItems, cardStyle }: WorkResultsProps) => {
  if (!workItems.length) {
    return null;
  }

  return (
    <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 sm:gap-8 xl:grid-cols-3 3xl:grid-cols-4 4xl:grid-cols-5 mb-8">
      {workItems.map((work: WorkEntity) => (
        <WorkCard key={work.objectID} work={work} cardStyle={cardStyle} />
      ))}
    </div>
  );
});

const WorkSearchList = React.memo(
  ({ filterContext, searchResults, work, onLoadMore }: Props) => {
    const navigate = useNavigate();
    const plan = useCurrentPlan();
    const { needsUpgrade } = useFeatureGate();
    const groupedWork = useMemo(() => {
      return groupWorkByExternal(work);
    }, [work]);

    if (!searchResults) {
      return null;
    }

    const showingCount =
      searchResults.hitsPerPage * searchResults.page +
      searchResults.hits.length;

    return (
      <div className="py-12 flex flex-col gap-8">
        <div className="mx-auto w-9/10">
          <div className="flex justify-between">
            <h2>Work</h2>
            <div>
              <Button
                color="primary"
                size="sm"
                text="Create new work post"
                onClick={() => {
                  if (
                    plan?.type === 'brand' ||
                    !needsUpgrade('canPostWorkToCommuno')
                  ) {
                    navigate('/work/create');
                  }
                }}
                icon="add"
              />
            </div>
          </div>
        </div>

        <Filter
          actions={filterContext.actions}
          filteredOptions={filterContext.filteredOptions}
          options={filterContext.options}
          values={filterContext.values}
        />

        <div className="mx-auto w-9/10">
          {searchResults.hits.length ? (
            <>
              {groupedWork.internal.length ? (
                <WorkResults
                  workItems={groupedWork.internal}
                  cardStyle="full"
                />
              ) : null}

              {groupedWork.external.length ? (
                <WorkResults
                  workItems={groupedWork.external}
                  cardStyle="external"
                />
              ) : null}

              <Paginate
                showing={showingCount}
                total={searchResults.nbHits}
                onLoadMore={onLoadMore}
              />
            </>
          ) : (
            <p>No results found!</p>
          )}
        </div>
      </div>
    );
  }
);
export default WorkSearchList;
